import React, { useEffect } from 'react'
import Button from './components/Button'
import Table from './components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import vendorStore from './stores/vendor'
import Modal from './components/Modal'
import { Text } from './components/Fields'
import { DateTime } from 'luxon'
import numeral from 'numeral'

export default function () {
  const [
    fetchVendors,
    vendors,
    vendor,
    setVendor,
  ] = vendorStore((state) => [
    state.fetch,
    state.collection,
    state.vendor,
    state.setVendor,
  ])

  useEffect(() => {
    fetchVendors()
  }, [])

  function newVendor() {
    setVendor({})
  }

  async function save() {
    const method = vendor.id ? 'PUT' : 'POST'
    const url = vendor.id ? `/api/vendors/${vendor.id}` : '/api/vendors'
    const res = await fetch(url, {
      method: method,
      body: JSON.stringify({vendor: vendor}),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    if (res.ok) {
      setVendor(false)
      return fetchVendors()
    }
  }

  return (
    <div>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <h1 className="text-4xl">Vendors</h1>
        <Button onClick={newVendor} type="success">
          New Vendor
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>ID</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Contact</Table.Th>
            <Table.Th>Phone</Table.Th>
            <Table.Th>Email</Table.Th>
            <Table.Th>Address</Table.Th>
            <Table.Th width="135"></Table.Th>
            <Table.Th width="135"></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {vendors && vendors.map(vendor => (
            <Row key={vendor.id} vendor={vendor} />
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={vendor}>
        <Modal.Header onClose={() => setVendor(false)}>
          {vendor.id ? 'Edit' : 'New'} Vendor
        </Modal.Header>
        <Modal.Body>
          <Text
            label="Title"
            onChange={e => setVendor({...vendor, title: e.target.value})} groupClass="mb-4"
            value={vendor.title}
          />
          <Text
            label="Website"
            onChange={e => setVendor({...vendor, website: e.target.value})} groupClass="mb-4"
            value={vendor.website}
          />
          <Text
            label="Contact Name"
            onChange={e => setVendor({...vendor, contact_name: e.target.value})} groupClass="mb-4"
            value={vendor.contact_name}
          />
          <Text
            label="Phone"
            onChange={e => setVendor({...vendor, phone_number: e.target.value})} groupClass="mb-4"
            value={vendor.phone_number}
          />
          <Text
            label="Email"
            onChange={e => setVendor({...vendor, email: e.target.value})} groupClass="mb-4"
            value={vendor.email}
          />
          <Text
            label="Address"
            onChange={e => setVendor({...vendor, address: e.target.value})} groupClass="mb-4"
            value={vendor.address}
          />
          <Text
            label="Address 2"
            onChange={e => setVendor({...vendor, address2: e.target.value})} groupClass="mb-4"
            value={vendor.address2}
          />
          <Text
            label="City"
            onChange={e => setVendor({...vendor, city: e.target.value})} groupClass="mb-4"
            value={vendor.city}
          />
          <Text
            label="State"
            onChange={e => setVendor({...vendor, state: e.target.value})} groupClass="mb-4"
            value={vendor.state}
          />
          <Text
            label="Zip"
            onChange={e => setVendor({...vendor, zip: e.target.value})} groupClass="mb-4"
            value={vendor.zip}
          />
          <Text
            label="Country"
            onChange={e => setVendor({...vendor, country: e.target.value})} groupClass="mb-4"
            value={vendor.country}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-between items-center">
            <a onClick={() => setVendor(false)} className="link">
              Cancel
            </a>
            <Button onClick={save} type="success">
              <FontAwesomeIcon icon={icon({name: 'floppy-disk'})} className="mr-2" />
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Row({ vendor }) {
  const [removing, setRemoving] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [deleteVendor, setVendor] = vendorStore((state) => [state.deleteVendor, state.setVendor])

  async function remove(e) {
    e.preventDefault()
    await deleteVendor(vendor)
    setRemoving(false)
  }

  return (
    <Table.Row>
      <Table.Td>{vendor.id}</Table.Td>
      <Table.Td>
        {vendor.website
          ? <a href={vendor.website} target="_blank">{vendor.title}</a>
          : vendor.title
        }
      </Table.Td>
      <Table.Td>{vendor.contact_name}</Table.Td>
      <Table.Td>{vendor.phone_number}</Table.Td>
      <Table.Td>{vendor.email}</Table.Td>
      <Table.Td>
        {vendor.address}<br />
        {vendor.address2}
        {vendor.address2 && <br />}
        {vendor.city}, {vendor.state} {vendor.zip}<br />
        {vendor.country}
      </Table.Td>
      <Table.Td>
        <Button type="danger" onClick={() => setRemoving(true)}>
          <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
          Remove
        </Button>
        <Modal isOpen={removing} onClose={() => setRemoving(false)}>
          <Modal.Header onClose={() => setRemoving(false)}>
            Are you sure?
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove vendor #{vendor.title}? This action cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={() => setRemoving(false)}>
                Cancel
              </a>
              <Button type="danger" onClick={remove}>
                <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
                Remove
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button onClick={() => setVendor(vendor)}>
          <FontAwesomeIcon icon={icon({name: 'pencil'})} className="mr-2" />
          Edit
        </Button>
      </Table.Td>
    </Table.Row>
  )
}
