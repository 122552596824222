import React, {useEffect, useState} from 'react'
import {v4 as uuid} from 'uuid'
// import Cleave from "cleave.js/react"
// import { FormGroup, ControlLabel, FormControl, HelpBlock, Checkbox, Radio, InputGroup } from 'react-bootstrap'
// import Select2 from 'react-select'

const inputClasses = `
  box-border
  bg-white
  block
  w-full
  px-2
  py-1
  leading-0
  border
  rounded
  transition-all
  duration-250
  focus:border-sky-500
  focus:shadow-field
  focus:shadow-sky-300
  outline-none
  group-[.has-error]:border-red-700
  group-[.has-error]:shadow-red-400
`

function FormGroup({children, errors, groupClass}) {
  return(
    <div className={`${groupClass} ${errors.length > 0 ? 'group has-error text-red-700': ''}`}>
      {children}
    </div>
  )
}
function ControlLabel({children}) {
  return(
    <label className="group-[.has-error]:text-red-700 inline-block font-bold mb-1 max-w-full">
      {children}
    </label>
  )
}
function HelpBlock({children}) {
  return(
    <p className="mb-0 mt-1 text-xs group-[.has-error]:text-red-700">{children}</p>
  )
}

function Field({errors = [], label, hint, children, groupClass}) {
  return(
    <FormGroup errors={errors} groupClass={groupClass}>
      {label &&
        <ControlLabel>{label}</ControlLabel>
      }
      {children}
      {hint && 
        <HelpBlock>{hint}</HelpBlock>
      }
      {errors && errors.length > 0 && errors.map((error) =>
        <HelpBlock key={error}>{error}</HelpBlock>
      )}
    </FormGroup>
  )
}

export function Text(props) {
  const {onKeyUp, value = '', type = 'text', onChange, placeholder, minLength, maxLength} = props
  return(
    <Field {...props}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={inputClasses}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        />
    </Field>
  )
}

export function Select(props) {
  const {options = [], name, value, onChange, valueKey, textKey} = props
  return(
    <Field {...props}>
      <select
        value={value}
        onChange={onChange}
        className={inputClasses}
      >
        <option value="">- select -</option>
        {options && options.map((option) =>
          <option value={option[valueKey]} key={option[valueKey]}>{option[textKey]}</option>
        )}
      </select>
    </Field>
  )
}

export function Boolean(props) {
  return <Radios {...props} options={[{value: true, text: 'Yes'}, {value: false, text: 'No'}]} />
}
// export function Boolean(props) {
//   const {errors = [], value = false, type = 'text', onChange, label, yesLabel, hint} = props
//   return(
//     <FormGroup errors={errors} groupClass="mb-4">
//       {label &&
//         <ControlLabel>{label}</ControlLabel>
//       }
//       <label className="flex gap-4">
//         <input
//           type="checkbox"
//           checked={value}
//           onChange={e => onChange(!!!value)}
//           style={{marginTop: "0"}}
//         />
//         {yesLabel ?? 'Yes'}
//       </label>
//       {hint && 
//         <HelpBlock>{hint}</HelpBlock>
//       }
//       {errors.length > 0 && errors.map((error) =>
//         <HelpBlock key={error}>{error}</HelpBlock>
//       )}
//     </FormGroup>
//   )
// }

export class Booleans extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const errors = this.props.errors[this.props.name] ?? []
    const value = this.props.object[this.props.name] ?? '0'
    const type = this.props.type ?? 'text'
    const checked = value == '1'
    return(
      <>
        <FormGroup className={errors.length > 0 ? 'has-error' : ''}>
          {this.props.label &&
            <ControlLabel>{this.props.label}</ControlLabel>
          }
          <Checkbox
            checked={checked}
            onChange={(e) => this.props.change(this.props.name, e.target.checked ? '1' : '0')}
            style={{marginTop: "0"}}
          >
            {this.props.yesLabel ?? 'Yes'}
          </Checkbox>
          {this.props.hint && 
            <HelpBlock>{this.props.hint}</HelpBlock>
          }
          {errors.length > 0 && errors.map((error) =>
            <HelpBlock key={error}>{error}</HelpBlock>
          )}
        </FormGroup>
      </>
    )
  }
}

// export function Text(props) {
//   const {onKeyUp, value = '', type = 'text', onChange, placeholder, minLength, maxLength} = props
//   return(
//     <Field {...props}>
//       <input
//         type={type}
//         value={value}
//         onChange={onChange}
//         className={inputClasses}
//         minLength={minLength}
//         maxLength={maxLength}
//         placeholder={placeholder}
//         onKeyUp={onKeyUp}
//         />
//     </Field>
//   )
// }

export function TextArea (props) {
  const { errors = [], value = '', type = 'text', onChange} = props

  return(
    <>
      <Field {...props}>
        <textarea
          type={type}
          value={value}
          onChange={onChange}
          className={inputClasses}
          rows='5'
        >
        </textarea>
      </Field>
    </>
  )
}

export function Radios(props) {
  const [name] = useState(uuid())
  const {value = '', onChange, options = []} = props
  return(
    <Field {...props}>
      <div className="flex justify-start gap-4">
        {options.map((option) =>
          <label className="flex items-center" key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={onChange}
              checked={option.value == value}
            />
            <span className="ml-2">{option.text}</span>
          </label>
        )}
      </div>
    </Field>
  )
}
