import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, NavLink } from "react-router-dom"
import logo from './static/magicbox.png';
import QuickAdd from './components/QuickAdd';
import { Text, TextArea } from './components/Fields'
import transactionStore from './stores/transaction';
import notificationStore from './stores/notification';
import Button from './components/Button'
import DirectionSelector from './components/DirectionSelector'
import Table from './components/Table'
import DestinationSelect from './components/DestinationSelect'
import RegionSelect from './components/RegionSelect'
import CategorySelect from './components/CategorySelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export default () => {
  const [minimized, setMinimized] = useState(false);
  const [
    items,
    updateQuantity,
    remove,
    clear,
    save,
    direction,
    purchase_order_id,
    po_number,
    setPoNumber,
    expected_on,
    setExpectedOn,
    notes,
    setNotes,
    category,
    setCategory,
    setDestinationIDs,
    destination_ids,
    setRegion,
    region_id,
  ] = transactionStore((state) => [
    state.items,
    state.updateQuantity,
    state.removeItem,
    state.clear,
    state.save,
    state.direction,
    state.purchase_order_id,
    state.po_number,
    state.setPoNumber,
    state.expected_on,
    state.setExpectedOn,
    state.notes,
    state.setNotes,
    state.category,
    state.setCategory,
    state.setDestinationIDs,
    state.destination_ids,
    state.setRegion,
    state.region_id,
  ]);

  const [notifications] = notificationStore((state) => [
    state.collection
  ])

  return (
    <>
      <div className="bg-white p-4 rounded-xl shadow-md mb-2">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-6">
            <img src={logo} alt="Magicbox" className="w-16 h-auto" />
            <h1 className="text-2xl font-bold">Magicbox</h1>
            <nav>
              <NavLink to="/" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Products</NavLink>
              <NavLink to="/transactions" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Transactions</NavLink>
              <NavLink to="/purchase-orders" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Purchase Orders</NavLink>
              <NavLink to="/reports" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Reports</NavLink>
              <NavLink to="/vendors" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Vendors</NavLink>
              <NavLink to="/loan-program" className={({isActive}) => `p-2 rounded ${isActive ? 'bg-sky-800 text-white' : ''}`}>Loan Program</NavLink>
            </nav>
          </div>
          <QuickAdd />
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl shadow-md">
        <Outlet />
      </div>
      {Object.keys(items).length > 0 && 
        <div className="fixed bottom-0 right-16 shadow-transaction rounded-t-lg max-h-full grid" style={{gridTemplateRows: "auto 1fr auto", maxHeight: "calc(100vh - 1rem)"}}>
          <div className="bg-sky-800 text-white font-bold p-2 rounded-t-lg">
            <div className="flex justify-between items-center gap-6">
              <div>Transaction</div>
              <div>
                {minimized
                  ? <FontAwesomeIcon icon={icon({name: 'window-maximize'})} className="cursor-pointer" onClick={e => setMinimized(false)} />
                  : <FontAwesomeIcon icon={icon({name: 'window-minimize'})} className="cursor-pointer" onClick={e => setMinimized(true)} />
                }
              </div>
            </div>
          </div>
          {!minimized && <>
            <div className="bg-white p-2 border-l border-r overflow-auto">
              <div className="mb-4">
                {!purchase_order_id && <DirectionSelector />}
              </div>
              {direction == -1 && <>
                <CategorySelect
                  onChange={setCategory}
                  value={category}
                />
                <DestinationSelect
                  onChange={setDestinationIDs}
                  value={destination_ids}
                />
                <RegionSelect
                  onChange={setRegion}
                  value={region_id}
                />
              </>}  
              {direction == 0 && <>
                <Text 
                  label="PO Number" 
                  value={po_number} 
                  onChange={e => setPoNumber(e.target.value)}
                  groupClass="mb-4"
                />
                <Text 
                  label="Expected On" 
                  type="date"  
                  value={expected_on} 
                  onChange={e => setExpectedOn(e.target.value)}
                  groupClass="mb-4"
                />
                <TextArea
                  label="Notes"
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  groupClass="mb-4"
                />
              </>}  
              <Table className="mb-4">
                <Table.Head>
                  <Table.Row>
                    <Table.Th>SKU</Table.Th>
                    <Table.Th>Quantity</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {Object.keys(items).map(id => (
                    <Table.Row key={id}>
                      <Table.Td>{items[id].sku}</Table.Td>
                      <Table.Td>
                        <input
                          type="number"
                          className="border rounded px-4 py-2"
                          placeholder="Quantity"
                          value={items[id].quantity}
                          onChange={e => updateQuantity(id, e.target.value)}
                        />
                      </Table.Td>
                      <Table.Td>
                        <Button
                          type="danger"
                          tabIndex="-1"
                          onClick={() => remove(id)}>
                          Remove
                        </Button>
                      </Table.Td>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Td className="text-right">Total:</Table.Td>
                    <Table.Td className="text-right">
                      {Object.values(items).reduce((acc, item) => acc + parseInt(item.quantity), 0)}
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div className="bg-white p-2">
              <div className="flex justify-between">
                <Button
                  type="danger"
                  onClick={() => clear()}>
                  Cancel
                </Button>
                <Button
                  type="success"
                  onClick={() => save()}>
                  Save
                </Button>
              </div>
            </div>
          </>}
        </div>
      }
      <div className='fixed top-4 right-4 flex flex-col items-end gap-2'>
        {notifications && notifications.map((notification => 
          <div key={notification.id} className={`p-2 rounded ${(notification.type == 'error') ? 'bg-red-500' : 'bg-green-500'} text-white max-w-lg w-auto`}>
            {notification.message}
          </div>
        ))}
      </div>
    </>
  );
}
