import React, { useEffect, useState, useContext, createContext, useMemo } from 'react'
import Button from './components/Button'
// import Table from './components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
// import Modal from './components/Modal'
import { TextArea } from './components/Fields'
import { DateTime } from 'luxon'
// import numeral from 'numeral'
import { Link, useParams } from 'react-router-dom'
import {produce} from 'immer'
// import { DndContext, useDroppable, useDraggable } from '@dnd-kit/core'
// import { CSS } from '@dnd-kit/utilities'
// import cn from './lib/cn'

const SerialNumberContext = createContext(null)

export default function () {

  const [serialNumber, setSerialNumber] = useState({item: {}})
  const [moves, setMoves] = useState([])

  const { item_id, id } = useParams()

  useEffect(() => {
    fetchSerialNumber()
    fetchMoves()
  }, [])

  async function fetchSerialNumber() {
    const res = await fetch(`/api/loan/items/${item_id}/serial_numbers/${id}.json`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setSerialNumber(data)
  }

  async function fetchMoves() {
    const res = await fetch(`/api/loan/items/${item_id}/serial_numbers/${id}/moves.json`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setMoves(data)
  }

  return (
    <SerialNumberContext.Provider value={{moves, setMoves}}>
      <p>
        <Link to={`/loan-program/${item_id}`}>&laquo; Back</Link>
      </p>
      <div className="flex justify-between gap-4 mb-8 items-end">
        <h1 className="text-4xl">Loan Program :: {serialNumber.item.title} :: {serialNumber.serial_number}</h1>
      </div>
      {moves.map(move => <Move key={move.id} move={move} />)}
    </SerialNumberContext.Provider>
  )
}

function Move({move}) {

  const [object, setObject] = useState(move)
  const { moves, setMoves } = useContext(SerialNumberContext)
  const changed = useMemo(() => move.comments != object.comments, [object.comments, move.comments])
  const { item_id, id } = useParams()

  async function save() {
    const res = await fetch(`/api/loan/items/${item_id}/serial_numbers/${id}/moves/${move.id}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({move: object})
    })
    if (!res.ok) { return }
    const data = await res.json()
    setObject(data)
    setMoves(produce(moves, draft => {
      const index = draft.findIndex(m => m.id == move.id)
      draft[index] = data
    }))
  }

  return <div className="p-4 mb-4 rounded bg-gray-100">
    <h2 className="mb-4">{DateTime.fromISO(move.created_at).toLocaleString(DateTime.DATETIME_SHORT)}</h2>
    <div className="grid grid-cols-[1fr_2rem_1fr] justify-between items-center mb-4">
      <h2 className="text-2xl">{move.from_title}</h2>
      <div className="text-center">
        <FontAwesomeIcon icon={icon({name: 'arrow-right'})} />
      </div>
      <h2 className="text-2xl text-right">{move.to_title}</h2>
    </div>
    <TextArea value={object.comments} onChange={e => setObject({...object, comments: e.target.value})} />
    {changed && 
      <div className="flex justify-end mt-4">
        <Button onClick={save}>Save</Button>
      </div>
    }
  </div>
}
