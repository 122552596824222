import { create } from 'zustand'
import { produce } from 'immer'
import productStore from './product'
import { persist } from 'zustand/middleware'
import notificationStore from './notification';
import purchaseOrderStore from './purchase_order';

export default create(
  persist(
    (set, get) => ({
      direction: -1,
      total: 0,
      page: 1,
      items: {},
      collection: [],
      po_number: "",
      purchase_order_id: null,
      expected_on: "",
      notes: "",
      category: null,
      destination_ids: [],
      region_id: null,
      lastRegionUUID: null,
      setLastRegionUUID: (uuid) => {
        set({ lastRegionUUID: uuid })
      },
      setPage: (page) => {
        set({ page: page })
      },
      setPurchaseOrderId: (value) => {
        set({ purchase_order_id: value })
      },
      setPoNumber: (value) => {
        set({ po_number: value })
      },
      setExpectedOn: (value) => {
        set({ expected_on: value })
      },
      setNotes: (value) => {
        set({ notes: value })
      },
      setCategory: (e) => {
        if (e == null) return set({ category: null })
        set({ category: e.value })
      },
      setDestinationIDs: (destinations) => {
        get().setLastRegionUUID(destinations[destinations.length - 1]?.id_Region)
        set({ destination_ids: destinations.map(d => d.value)})
      },
      setRegion: (e) => {
        if (e == null) return set({ region_id: null })
        set({ region_id: e.value })
      },
      fetch: async () => {
        const response = await fetch(`/api/inventory_transactions.json?page=${get().page}`)
        const data = await response.json()
        set({ collection: data.collection, total: data.total })
      },
      add: (product, quantity = 1) => {
        const items = get().items
        const newItems = { ...items }
        newItems[product.id] = {
          ...product,
          quantity: items[product.id] ? items[product.id].quantity + quantity : quantity
        }
        notificationStore.getState().add({
          message: `${product.title} has been added`,
          type: 'success'
        })
        set({ items: newItems })
      },
      updateQuantity: (id, quantity) => {
        set(produce(state => {
          state.items[id].quantity = quantity
        }))
      },
      update: async ({id, ...transaction}) => {
        const response = await fetch(`/api/inventory_transactions/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({inventory_transaction: transaction})
        })
        const data = await response.json()
        const newTransactions = [...get().collection]
        const index = newTransactions.findIndex(t => {console.log(t.id, data.id); return t.id == data.id})
        if (index >= 0) {
          newTransactions.splice(index, 1, data)
          set({ collection: newTransactions })
          notificationStore.getState().add({
            message: `Transaction updated`,
            type: 'success'
          })
        }
        const newPurchaseOrders = [...purchaseOrderStore.getState().collection]
        const po_index = newPurchaseOrders.findIndex(t => t.id == data.id)
        if (po_index >= 0) {
          newPurchaseOrders.splice(po_index, 1, data)
          purchaseOrderStore.getState().setCollection(newPurchaseOrders)
          notificationStore.getState().add({
            message: `Purchase order updated`,
            type: 'success'
          })
        }
      },
      delete: async ({id}) => {
        await fetch(`/api/inventory_transactions/${id}`, {
          method: 'DELETE'
        })
        const newTransactions = [...get().collection]
        const index = newTransactions.findIndex(t => t.id == id)
        newTransactions.splice(index, 1)
        if (index >= 0) {
          set({ collection: newTransactions })
          notificationStore.getState().add({
            message: `Transaction deleted`,
            type: 'success'
          })
        }
        const newPurchaseOrders = [...purchaseOrderStore.getState().collection]
        const po_index = newPurchaseOrders.findIndex(t => t.id == id)
        if (po_index >= 0) {
          newPurchaseOrders.splice(po_index, 1)
          purchaseOrderStore.getState().setCollection(newPurchaseOrders)
          notificationStore.getState().add({
            message: `Purchase order deleted`,
            type: 'success'
          })
        }
      },
      removeItem: (id) => {
        set(produce(state => {
          delete state.items[id]
        }))
      },
      clear: () => {
        set({ 
          items: {},
          po_number: "",
          purchase_order_id: null,
          expected_on: "",
          notes: "",
          category: null,
          destination_ids: [],
          region_id: null,
          lastRegionUUID: null,
         })
      },
      setDirection: (direction) => {
        set({ direction: direction })
      },
      save: async () => {
        const collection = [...get().collection]
        const po_collection = [...purchaseOrderStore.getState().collection]
        const items = get().items
        const params = {
          direction: get().direction,
          purchase_order_id: get().purchase_order_id,
          po_number: get().po_number,
          expected_on: get().expected_on,
          notes: get().notes,
          category: get().category,
          destination_ids: get().destination_ids,
          region_id: get().region_id,
          items_attributes: Object.values(items).map(item => ({
            product_id: item.id,
            quantity: item.quantity
          }))
        }
        try {
          const response = await fetch('/api/inventory_transactions.json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({inventory_transaction: params})
          })
          if (!response.ok) {
            throw new Error(response)
          }
          const data = await response.json()
          if (data.direction == 0 ) {
            po_collection.unshift(data)
          } else if (get().page == 1) {
            collection.unshift(data)
          }
          if (data.purchase_order_id) {
            const transactionIndex = po_collection.findIndex(transaction => transaction.id == data.purchase_order_id)
            po_collection.splice(transactionIndex, 1, data.purchase_order)
          }
          set({collection: collection})
          purchaseOrderStore.getState().setCollection(po_collection)
          get().clear()
          productStore.getState().updates(data.products)
          notificationStore.getState().add({
            message: `Transaction saved`,
            type: 'success'
          })
        } catch (err) {
          notificationStore.getState().add({
            message: `There has been an error`,
            type: 'error'
          })
        } 
      }
    }), {
      name: 'transaction-storage',
      partialize: (state) => {
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !['collection'].includes(key))
        )
      },
    }
  )
)
