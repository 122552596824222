import React, { useEffect, useState } from 'react'
import Button from './components/Button'
import Table from './components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Modal from './components/Modal'
import { Text } from './components/Fields'
import { DateTime } from 'luxon'
import numeral from 'numeral'

export default function () {

  const [item, setItem] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    fetchItems()
  }, [])

  async function fetchItems() {
    const res = await fetch('/api/loan/items.json', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setItems(data)
  }

  async function save() {
    const res = await fetch('/api/loan/items.json', {
      method: 'POST',
      body: JSON.stringify({item}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setItem(false)
    fetchItems()
  }

  return (
    <div>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <h1 className="text-4xl">Loan Program</h1>
        <Button onClick={() => setItem({})} type="success">
          New Item
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>ID</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Quantity In Office</Table.Th>
            <Table.Th>Quantity In Field</Table.Th>
            <Table.Th width="135"></Table.Th>
            <Table.Th width="135"></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {items.map(item => (
            <Row key={item.id} item={item} fetchItems={fetchItems} />
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={item}>
        <Modal.Header onClose={() => setItem(false)}>
          New Item
        </Modal.Header>
        <Modal.Body>
          <Text
            label="Title"
            onChange={e => setItem({...item, title: e.target.value})} groupClass="mb-4"
            value={item.title}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-between items-center">
            <a onClick={() => setItem(false)} className="link">
              Cancel
            </a>
            <Button onClick={save} type="success">
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Row({ item, fetchItems }) {
  const [removing, setRemoving] = useState(false)

  async function remove(e) {
    e.preventDefault()
    const res = await fetch(`/api/loan/items/${item.id}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setRemoving(false)
    fetchItems()
  }

  return (
    <Table.Row>
      <Table.Td>{item.id}</Table.Td>
      <Table.Td>{item.title}</Table.Td>
      <Table.Td className="text-right">{item.quantity_office}</Table.Td>
      <Table.Td className="text-right">{item.quantity_field}</Table.Td>
      <Table.Td>
        <Button type="danger" onClick={() => setRemoving(true)}>
          <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
          Remove
        </Button>
        <Modal isOpen={removing} onClose={() => setRemoving(false)}>
          <Modal.Header onClose={() => setRemoving(false)}>
            Are you sure?
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove item #{item.id} ({item.title})? This action cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={() => setRemoving(false)}>
                Cancel
              </a>
              <Button type="danger" onClick={remove}>
                <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
                Remove
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button to={`/loan-program/${item.id}`}>
          <FontAwesomeIcon icon={icon({name: 'circle-info'})} className="mr-2" />
          Details
        </Button>
      </Table.Td>
    </Table.Row>
  )
}
