import React, { useEffect, useState, useContext, createContext } from 'react'
import Button from './components/Button'
import Table from './components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Modal from './components/Modal'
import { Text } from './components/Fields'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { Link, useParams } from 'react-router-dom'
import {produce} from 'immer'
import { DndContext, useDroppable, useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import cn from './lib/cn'

const LoanProgramContext = createContext(null)

export default function () {

  const [item, setItem] = useState({})
  const [location, setLocation] = useState(false)
  const [locations, setLocations] = useState([])
  const [serialNumber, setSerialNumber] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  const [search, setSearch] = useState('')

  const { id } = useParams()

  const {isOver, setNodeRef} = useDroppable({
    id: 'drop-1000',
  })

  useEffect(() => {
    fetchItem()
    fetchLocations()
  }, [])

  async function fetchItem() {
    const res = await fetch(`/api/loan/items/${id}.json`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setItem(data)
  }

  async function fetchLocations() {
    const res = await fetch(`/api/loan/items/${id}/locations.json`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setLocations(data)
  }

  async function save() {
    const res = await fetch(`/api/loan/items/${id}/locations.json`, {
      method: 'POST',
      body: JSON.stringify({location}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setLocation(false)
    fetchLocations()
  }

  async function saveSerialNumber() {
    const res = await fetch(`/api/loan/items/${id}/serial_numbers.json`, {
      method: 'POST',
      body: JSON.stringify({serial_number: serialNumber}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setSerialNumber(false)
    setItem(produce(item, draft => {
      draft.serial_numbers = [...draft.serial_numbers, data]
    }))
  }

  async function move(event) {
    const {active: {data: {current: {serialNumber}}}, over: {data: {current: {location}}}} = event
    if (!serialNumber) return
    const res = await fetch(`/api/loan/items/${id}/serial_numbers/${serialNumber.id}.json`, {
      method: 'PATCH',
      body: JSON.stringify({location_id: location?.id}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) { return }
    const data = await res.json()
    setLocations(produce(locations, draft => {
      // find the location where the serial number was
      const oldIndex = draft.findIndex(l => l.serial_numbers.findIndex(s => s.id == data.id) !== -1)
      // if it was in a location, remove it
      if (oldIndex > -1) {
        draft[oldIndex].serial_numbers = draft[oldIndex].serial_numbers.filter(s => s.id !== data.id)
      }
      // if the serial number is in a location, add it
      if (data.location_id) {
        const index = draft.findIndex(l => l.id === data.location_id)
        draft[index].serial_numbers.push(data)
      }
    }))
    setItem(produce(item, draft => {
      const index = draft.serial_numbers.findIndex(s => s.id === data.id)
      if (index > -1) {
        draft.serial_numbers.splice(index, 1)
      }
      if (!data.location_id) {
        draft.serial_numbers.push(data)
      }
    }))
  }

  return (
    <LoanProgramContext.Provider value={{item, locations, setLocations, setItem, showRemove, search}}>
      <DndContext onDragEnd={move}>
        <p>
          <Link to="/loan-program">&laquo; Back</Link>
        </p>
        <div className="flex justify-between gap-4 mb-8 items-end">
          <h1 className="text-4xl">Loan Program :: {item.title}</h1>
          <div className="flex gap-4 items-center">
            <label className="flex items-center gap-2 cursor-pointer select-none">
              <input type="checkbox" checked={showRemove} onChange={e => setShowRemove(e.target.checked)} />
              Show Buttons
            </label>
            <Text
              label={false}
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <Button onClick={() => setSerialNumber({})} type="success">
              New Serial Number
            </Button>
            <Button onClick={() => setLocation({})} type="success">
              New Location
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grow">
            <div className="p-4 mb-4 rounded bg-gray-100 sticky top-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">NHA Office</h2>
              </div>
              <Droppable>
                {item.serial_numbers?.map(serialNumber => <SerialNumber key={serialNumber.id} serialNumber={serialNumber} />)}
              </Droppable>
            </div>
          </div>
          <div className="grow">
            {locations.map(location => <Location key={location.id} location={location} />)}
          </div>
        </div>
        <Modal isOpen={serialNumber}>
          <Modal.Header onClose={() => setLocation(false)}>
            New Serial Number
          </Modal.Header>
          <Modal.Body>
            <Text
              label="Serial Number"
              onChange={e => setSerialNumber({...serialNumber, serial_number: e.target.value})} groupClass="mb-4"
              value={serialNumber.serial_number}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between items-center">
              <a onClick={() => setSerialNumber(false)} className="link">
                Cancel
              </a>
              <Button onClick={saveSerialNumber} type="success">
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal isOpen={location}>
          <Modal.Header onClose={() => setLocation(false)}>
            New Location
          </Modal.Header>
          <Modal.Body>
            <Text
              label="Title"
              onChange={e => setLocation({...location, title: e.target.value})} groupClass="mb-4"
              value={location.title}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between items-center">
              <a onClick={() => setLocation(false)} className="link">
                Cancel
              </a>
              <Button onClick={save} type="success">
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </DndContext>
    </LoanProgramContext.Provider>
  )
}

function Location({ location }) {
  const [removing, setRemoving] = useState(false)
  const {setItem, setLocations, item, locations, showRemove} = useContext(LoanProgramContext)
  async function remove() {
    const res = await fetch(`/api/loan/items/${item.id}/locations/${location.id}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) { return }
    setRemoving(false)
    setItem(produce(item, draft => {
      draft.serial_numbers = [...draft.serial_numbers, ...location.serial_numbers]
    }))
    setLocations(produce(locations, draft => {
      return draft.filter(l => l.id !== location.id)
    }))
  }
  return <div className="p-4 mb-4 rounded bg-gray-100">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold">{location.title}</h2>
      {showRemove &&
        <Button type="danger" onClick={() => setRemoving(true)}>
          Remove
        </Button>
      }
    </div>
    <Droppable location={location}>
      {location.serial_numbers?.map(serialNumber => <SerialNumber key={serialNumber.id} serialNumber={serialNumber} />)}
    </Droppable>
    <Modal isOpen={removing}>
      <Modal.Header onClose={() => setRemoving(false)}>
        Are you sure?
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to remove location "{location.title}"? This action cannot be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center justify-between">
          <a href="#" onClick={() => setRemoving(false)}>
            Cancel
          </a>
          <Button type="danger" onClick={remove}>
            <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
            Remove
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  </div>
}

function SerialNumber({ serialNumber }) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `drag-${serialNumber.id}`,
    data: {serialNumber}
  })
  const style = {
    transform: CSS.Translate.toString(transform),
  }
  const { id } = useParams()
  const {item, setItem, locations, setLocations, showRemove, search} = useContext(LoanProgramContext)
  const [removing, setRemoving] = useState(false)
  const remove = async () => {
    const res = await fetch(`/api/loan/items/${item.id}/serial_numbers/${serialNumber.id}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) { return }
    setLocations(produce(locations, draft => {
      for (const location of draft) {
        location.serial_numbers = location.serial_numbers.filter(s => s.id !== serialNumber.id)
      }
    }))
    setItem(produce(item, draft => {
      draft.serial_numbers = draft.serial_numbers.filter(s => s.id !== serialNumber.id)
    }))
    setRemoving(false)
  }
  return <div className={cn("rounded bg-gray-200", search && serialNumber.serial_number.match(search) && 'bg-blue-700 text-white')} ref={setNodeRef} style={style}>
    <div className="flex items-center">
      <div className="p-2" {...listeners} {...attributes}>
        {serialNumber.serial_number}
      </div>
      {showRemove && <>
        <Link to={`/loan-program/${id}/serial-number/${serialNumber.id}`}>
          <FontAwesomeIcon
            icon={icon({name: 'eye'})}
            className="text-blue-500 p-2 cursor-pointer"
          />
        </Link>
        <FontAwesomeIcon
          icon={icon({name: 'trash'})}
          className="text-red-500 p-2 cursor-pointer"
          onClick={() => setRemoving(true)}
        />
      </>}
    </div>
    <Modal isOpen={removing}>
      <Modal.Header onClose={() => setRemoving(false)}>
        Are you sure?
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to remove serial number {serialNumber.serial_number}? This action cannot be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center justify-between">
          <a href="#" onClick={() => setRemoving(false)}>
            Cancel
          </a>
          <Button type="danger" onClick={remove}>
            <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
            Remove
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  </div>
}

function Droppable({ children, location = null }) {
  const config = {}
  if (location) {
    config.id = `drop-${location.id}`
    config.data = {location}
  } else {
    config.id = 'drop-0'
    config.data = {location: {id: null}}
  }
  const {isOver, setNodeRef} = useDroppable(config)

  return(
    <div
      ref={setNodeRef}
      className="min-h-24 p-2 flex items-start gap-2 rounded border-2 border-dashed border-gray-400 flex-wrap"
    >
      {children}
    </div>
  )
}
