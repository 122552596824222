import React, { useState, useEffect, useRef, useContext, createContext, useMemo } from 'react'
import Table from './components/Table'
import { useParams } from 'react-router-dom'
import numeral from 'numeral'
import { categories as defaultCategories } from './components/CategorySelect'
import Loading from './components/Loading'
import Button from './components/Button'
import TableToExcel from './lib/table-to-excel'

const ReportContext = createContext()

const ExcelTable = ({ title, children }) => {
  const ref = useRef(null)
  const report = useContext(ReportContext)
  function exportTableToCsv() {
    const table = ref.current.querySelector('table').cloneNode(true)
    table.querySelectorAll('td[data-t="n"]').forEach(td => {
      td.textContent = numeral(td.textContent).value()
    })
    TableToExcel.convert(table, {
      name: report.start_date + '_' + report.end_date + '_' +title.toLowerCase().replace(/[^a-z0-9]/g, "-") + ".xlsx",
      sheet: {
        name: 'Sheet 1',
        ySplit: 1,
      }
    })
  }
  return (
    <div>
      <div className="flex justify-between gap-4 mb-4">
        <h1 className="text-4xl">{title}</h1>
        <Button onClick={exportTableToCsv}>Export to Excel</Button>
      </div>
      <div ref={ref}>
        {children}
      </div>
    </div>
  )
}

export default function () {

  const { id } = useParams()
  const [categories, setCategories] = useState(defaultCategories)
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState({})

  useEffect(() => {
    fetch(`/api/reports/${id}.json`)
      .then(res => res.json())
      .then(setReport)
      .then(() => setLoading(false))
  }, [id])

  const totalPriceByFinanceCategory = (financeCategory = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (financeCategory) {
          return transaction.destinations.findIndex((d) => d.financeCategory == financeCategory) >= 0
        }
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_price / parseFloat(financeCategory ? transaction.destination_ids.length : 1), 0)
  }

  const totalCostByFinanceCategory = (financeCategory = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (financeCategory) {
          return transaction.destinations.findIndex((d) => d.fincacneCategory == financeCategory) >= 0
        }
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_cost / parseFloat(financeCategory ? transaction.destination_ids.length : 1), 0)
  }

  const totalPriceByDestination = (destinationId = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (destinationId) return transaction.destination_ids.indexOf(destinationId) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_price / parseFloat(destinationId ? transaction.destination_ids.length : 1), 0)
  }

  const totalCostByDestination = (destinationId = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (destinationId) return transaction.destination_ids.indexOf(destinationId) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_cost / parseFloat(destinationId ? transaction.destination_ids.length : 1), 0)
  }

  const totalPriceByRegion = (regionId = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (regionId) return transaction.region_id == regionId
        return transaction.region_id == null
      })
      .reduce((acc, transaction) => acc + transaction.total_price, 0)
  }

  const totalCostByRegion = (regionId = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (regionId) return transaction.region_id == regionId
        return transaction.region_id == null
      })
      .reduce((acc, transaction) => acc + transaction.total_cost, 0)
  }

  const totalPriceByCategory = (category = false, swag = null) => {
    return filteredTransactions
      .filter(transaction => {
        if (transaction.category == null && category == '') return true
        if (category) return transaction.category == category
      })
      .reduce((acc, transaction) => {
        return acc + transaction.items_attributes.reduce((acc2, item) => {
          const totalPrice = parseInt(item.quantity ?? 0) * parseFloat(item.price ?? 0)
          if (swag === null) return acc2 + totalPrice
          if (swag === true && item.swag !== true) return acc2
          if (swag === false && item.swag === true) return acc2
          return acc2 + totalPrice
        }, 0)
      }, 0)
  }

  const totalCostByCategory = (category = false, swag = null) => {
    return filteredTransactions
      .filter(transaction => { 
        if (transaction.category == null && category == '') return true
        if (category) return transaction.category == category
      })
      .reduce((acc, transaction) => {
        return acc + transaction.items_attributes.reduce((acc2, item) => {
          const totalCost = parseInt(item.quantity ?? 0) * parseFloat(item.cost ?? 0)
          if (swag === null) return acc2 + totalCost
          if (swag === true && item.swag !== true) return acc2
          if (swag === false && item.swag === true) return acc2
          return acc2 + totalCost
        }, 0)
      }, 0)
  }

  const updateCategories = (value, e) => {
    setCategories(categories.map(category => {
      if (category.value == value) {
        return { ...category, selected: e.target.checked }
      } else {
        return category
      }
    }))
  }

  const filteredTransactions = useMemo(
    () => {
      if (!report.transactions?.length) return
      const selectedCategories = categories.filter(category => category.selected).map(category => category.value == '' ? null : category.value)
      const transactions = report.transactions.map(transaction => {
        return {
          ...transaction,
          destinations: transaction.destination_ids.map(destinationId => report.destinations.find(destination => destination.record_id == destinationId)),
        }
      })
      if (selectedCategories.length == 0) return transactions
      return transactions.filter(transaction => {
        return selectedCategories.indexOf(transaction.category) != -1
      })
    },
    [report.transactions, categories]
  )

  const financeCategories = useMemo(
    () => {
      if (!report.destinations?.length) return []
      return report
        .destinations
        .filter(destination => destination.financeCategory)
        .map(destination => [destination.financeCategory, destination.tourcode])
        .sort((a, b) => a[0] > b[0] ? 1 : -1)
    },
    [report.destinations]
  )

  if (loading) return <Loading />

  const region = (regionId) => {
    if (!report.regions?.length) return ''
    return report.regions.find(region => region.record_id == regionId)?.name
  }

  const destinations = (destinationIds) => {
    if (!report.destinations?.length) return ''
    return destinationIds.map(destinationId => report.destinations.find(destination => destination.record_id == destinationId)?.name).join(', ')
  }

  return (
    <ReportContext.Provider value={report}>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <div className='flex gap-4'>
          <h1 className="text-4xl">Categories</h1>
        </div>
      </div>
      <div className="mb-12">
        <div className="flex gap-2 flex-wrap mb-4">
          {categories.map(category =>
            <label key={category.value} className="flex gap-2 p-2 bg-gray-100 rounded">
              <input type="checkbox" onChange={(e) => updateCategories(category.value, e)} checked={category.selected ?? false} />
              {category.label == '- select -' ? 'Uncategorized' : category.label}
            </label>
          )}
        </div>
      </div>
      <div className="mb-12">
        <ExcelTable title="Totals by Category">
          <Table
            className="mb-8"
            data-f-name="Calibri"
            data-f-sz="16"
            data-frozen-rows="2"
            data-cols-width="
              25,
              15,
              15,
              15,
              15,
              15,
              15,
            "
          >
            <Table.Head>
              <Table.Row>
                <Table.Th rowSpan="2" data-fill-color="e8e8e8">Category</Table.Th>
                <Table.Th colSpan="2" data-fill-color="e8e8e8">Swag</Table.Th>
                <Table.Th colSpan="2" data-fill-color="e8e8e8">Not-Swag</Table.Th>
                <Table.Th colSpan="2" data-fill-color="e8e8e8">Total</Table.Th>
              </Table.Row>
              <Table.Row>
                <Table.Th data-fill-color="e8e8e8">Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Cost</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Cost</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {categories.filter((c) => c.selected).map(category => (
                <Table.Row key={category.value}>
                  <Table.Td>{category.label == '- select -' ? 'Uncategorized' : category.label}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByCategory(category.value, true)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByCategory(category.value, true)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByCategory(category.value, false)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByCategory(category.value, false)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByCategory(category.value, null)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByCategory(category.value, null)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ExcelTable>
      </div>
      <div className="mb-12">
        <ExcelTable title="Totals by Region">
          <Table
            className="mb-8"
            data-f-name="Calibri"
            data-f-sz="16"
            data-frozen-rows="1"
            data-cols-width="
              25,
              15,
              15,
            "
          >
            <Table.Head>
              <Table.Row>
                <Table.Th data-fill-color="e8e8e8">Region</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Region</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByRegion()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByRegion()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {report.regions?.map(region => (
                <Table.Row key={region.record_id}>
                  <Table.Td>{region.name}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByRegion(region.record_id)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByRegion(region.record_id)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ExcelTable>
      </div>
      <div className="mb-12">
        <ExcelTable title="Totals by Finance Category">
          <Table
            className="mb-8"
            data-f-name="Calibri"
            data-f-sz="16"
            data-frozen-rows="1"
            data-cols-width="
              35,
              15,
              15,
              15,
            "
          >
            <Table.Head>
              <Table.Row>
                <Table.Th data-fill-color="e8e8e8">Finance Category</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Tourcode</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Category</Table.Td>
                <Table.Td>No Tourcode</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByFinanceCategory()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByFinanceCategory()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {financeCategories.map(([category, tourcode]) => (
                <Table.Row key={tourcode}>
                  <Table.Td>{category}</Table.Td>
                  <Table.Td>{tourcode}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByFinanceCategory(category)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByFinanceCategory(category)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ExcelTable>
      </div>
      <div className="mb-12">
        <ExcelTable title="Totals by Destination">
          <Table
            className="mb-8"
            data-f-name="Calibri"
            data-f-sz="16"
            data-frozen-rows="1"
            data-cols-width="
              45,
              15,
              15,
            "
          >
            <Table.Head>
              <Table.Row>
                <Table.Th data-fill-color="e8e8e8">Destination</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Price</Table.Th>
                <Table.Th data-fill-color="e8e8e8">Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Destination</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByDestination()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByDestination()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {report.destinations?.map(destination => (
                <Table.Row key={destination.record_id}>
                  <Table.Td>{destination.name}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalPriceByDestination(destination.record_id)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(totalCostByDestination(destination.record_id)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ExcelTable>
      </div>
      <ExcelTable title="Transactions">
        <Table
          data-f-name="Calibri"
          data-f-sz="16"
          data-frozen-rows="1"
          data-cols-width="
            22,
            25,
            20,
            25,
            12,
            35,
            35,
            12,
            12,
            12,
            12,
            12,
            12
          "
        >
          <Table.Head>
            <Table.Row>
              <Table.Th data-fill-color="e8e8e8">Date</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Category</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Region</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Destination</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Product ID</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Parent</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Product</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Quantity Ordered</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Price</Table.Th>
              <Table.Th data-fill-color="e8e8e8">Cost</Table.Th>
              <Table.Th width="120" data-fill-color="e8e8e8">Total Count</Table.Th>
              <Table.Th width="120" data-fill-color="e8e8e8">Total Price</Table.Th>
              <Table.Th width="120" data-fill-color="e8e8e8">Total Cost</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredTransactions.map(transaction => <>
              <Table.Row key={transaction.id}>
                <Table.Td rowSpan={transaction.items_attributes.length}>{transaction.created_at}</Table.Td>
                <Table.Td rowSpan={transaction.items_attributes.length}>{transaction.category}</Table.Td>
                <Table.Td rowSpan={transaction.items_attributes.length}>{region(transaction.region_id)}</Table.Td>
                <Table.Td rowSpan={transaction.items_attributes.length}>{destinations(transaction.destination_ids)}</Table.Td>
                {transaction.items_attributes && <>
                  <Table.Td data-t="n">
                    {transaction.items_attributes[0].magento_id}
                  </Table.Td>
                  <Table.Td>
                    {transaction.items_attributes[0].parent_title}
                  </Table.Td>
                  <Table.Td>
                    {transaction.items_attributes[0].title}
                  </Table.Td>
                  <Table.Td data-t="n">
                    {transaction.items_attributes[0].quantity}
                  </Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">
                    {numeral(transaction.items_attributes[0].quantity * parseFloat(transaction.items_attributes[0].price)).format('$0,0.00')}
                  </Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">
                    {numeral(transaction.items_attributes[0].quantity * parseFloat(transaction.items_attributes[0].cost)).format('$0,0.00')}
                  </Table.Td>
                </>}
                <Table.Td rowSpan={transaction.items_attributes.length} className="text-right" data-t="n">{transaction.product_count}</Table.Td>
                <Table.Td rowSpan={transaction.items_attributes.length} className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(transaction.total_price).format('$0,0.00')}</Table.Td>
                <Table.Td rowSpan={transaction.items_attributes.length} className="text-right" data-t="n" data-num-fmt="$#,##0.00">{numeral(transaction.total_cost).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {transaction.items_attributes.slice(1).map((item) => <>
                <Table.Row key={item.id}>
                  <Table.Td data-t="n">
                    {item.magento_id}
                  </Table.Td>
                  <Table.Td>
                    {item.parent_title}
                  </Table.Td>
                  <Table.Td>
                    {item.title}
                  </Table.Td>
                  <Table.Td data-t="n">
                     {item.quantity}
                  </Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">
                    {numeral(item.quantity * parseFloat(transaction.items_attributes[0].price)).format('$0,0.00')}
                  </Table.Td>
                  <Table.Td className="text-right" data-t="n" data-num-fmt="$#,##0.00">
                    {numeral(item.quantity * parseFloat(transaction.items_attributes[0].cost)).format('$0,0.00')}
                  </Table.Td>
                </Table.Row>
              </>)}
            </>)}
          </Table.Body>
        </Table>
      </ExcelTable>
    </ReportContext.Provider>
  )
}
