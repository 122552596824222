import { create } from 'zustand'
import notificationStore from './notification';

export default create((set, get) => ({
  collection: [],
  vendor: false,
  setVendor(vendor) {
    set({ vendor })
  },
  async deleteVendor({id}) {
    await fetch(`/api/vendors/${id}`, {
      method: 'DELETE'
    })
    const newVendors = [...get().collection]
    const index = newVendors.findIndex(t => t.id == id)
    newVendors.splice(index, 1)
    set({ collection: newVendors })
    notificationStore.getState().add({
      message: `Vendor deleted`,
      type: 'success'
    })
  },
  fetch: async () => {
    const response = await fetch('/api/vendors')
    const data = await response.json()
    set({ collection: data })
  },
}))
