import React from 'react'
import Layout from './Layout'
import Products from './Products'
import Transactions from './Transactions'
import PurchaseOrders from './PurchaseOrders';
import Reports from './Reports';
import LoanProgram from './LoanProgram';
import LoanProgramItem from './LoanProgramItem';
import LoanProgramSerialNumber from './LoanProgramSerialNumber';
import Report from './Report';
import Vendors from './Vendors';

import {
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([{
  path: "/",
  element: <Layout />,
  errorElement: <h1>404</h1>,
  children: [{
    index: true,
    element: <Products />,
  }, {
    path: "transactions",
    element: <Transactions />,
  }, {
    path: "purchase-orders",
    element: <PurchaseOrders />,
  }, {
    path: "reports",
    element: <Reports />,
  }, {
    path: "reports/:id",
    element: <Report />,
  }, {
    path: "vendors",
    element: <Vendors />,
  }, {
    path: "loan-program",
    children: [{
      index: true,
      element: <LoanProgram />,
    }, {
      path: ":id",
      element: <LoanProgramItem />,
    }, {
      path: ":item_id/serial-number/:id",
      element: <LoanProgramSerialNumber />,
    }],
  }],
}], {basename: "/"})

export default function () {
  return (
    <RouterProvider router={router} />
  )
}
